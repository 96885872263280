import React, { useState, useEffect } from "react";
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import Tooltip from 'common/src/components/Tooltip';
import Container from 'common/src/components/UI/ContainerTwo';
import BannerWrapper, {
  BannerContent,
  Subscribe,
  SponsoredBy,
  ImageGroup,
} from './banner.style';

import { Icon } from 'react-icons-kit';
import { checkmark } from 'react-icons-kit/ionicons/checkmark';

import paypal from 'common/src/assets/image/agencyModern/paypal.png';
import google from 'common/src/assets/image/agencyModern/google.png';
import dropbox from 'common/src/assets/image/agencyModern/dropbox.png';
import KonenIcon from './../Footer/konen-logo.inline.svg';

import invert from 'invert-color';

import { useForm } from "react-hook-form";

const Banner = ({ internalDomainKey, headlineText, introText, disableSponsor, backgroundImage, backgroundColor }) => {

  const { handleSubmit, register, errors } = useForm();
  const [ subscribeEmail, setSubscribeEmail ] = useState('');
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ submitError, setSubmitError ] = useState(false);

  const onSubmit = values => 
  {
    console.log(values);

    const postData = {
      email : values.subscribeemail,
      internalDomainKey : internalDomainKey
    };

    var postBody = JSON.stringify(postData);      
    console.log(postBody);

    // Post review to the API for submission.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    fetch(`api/submit-subscribe`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: postBody 
      })
      .then(response => {
          // check for 200-299 status codes
          if (!response.ok) {
            console.log('Network response was not ok');
            setSubmitError(true)
            return;
          }

          setIsSubmitted(true);
          return response;
      }) // parse JSON from request
      .then(resultData => 
      {
          console.log(resultData)        
      })
      .catch((error) => {
        console.error('Unable to connect due to this error:', error);
        setSubmitError(true);
      })
  }

  return (
    <BannerWrapper id="home" style={{ backgroundImage : `url(${backgroundImage.fluid.src})`, backgroundColor : backgroundColor }}>
      <Container>
        <BannerContent>
          <Heading
            as="h1"
            content={headlineText}
            style={{ color: invert(backgroundColor, true) }}
          />
          <Text
            className="banner-caption"
            content={introText.replace(/(<([^>]+)>)/gi, "")}
            style={{ color: invert(backgroundColor, true) }}
          />
          <Subscribe data-netlify="false" onSubmit={handleSubmit(onSubmit)}>
           
            <Input
              displayLabel={false}
              label="subscribeemail"                
              inputType="email"
              placeholder="Tu correo electrónico"
              iconPosition="left"
              aria-label="email" 
              disabled={isSubmitted}
              inputRef={register({
                required: "Tu correo es requerido para verificar tu identidad.",  
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Tu correo debe ser válido."
                }                      
              })}
            />
            
            {!isSubmitted &&
              <Button title="Subscribir" type="submit" />
            }
            {isSubmitted &&
              <Button title="" type="submit" disabled={true} style={{ backgroundColor : "green" }}
                iconPosition="left" 
                icon={<Icon
                  style={{ color: 'white' }}
                  className="bar"
                  size={32}
                  icon={checkmark}
                />} />
            }
           
          </Subscribe>   
          {!disableSponsor &&
            <SponsoredBy>
              <Text className="sponsoredBy" content="Desarollado por:" style={{ color: invert(backgroundColor, true) }} />
              <ImageGroup>                
                <KonenIcon style={{height: "80px", width: "120px" }} />                
              </ImageGroup>
            </SponsoredBy>
          }   
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
