import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Container from 'common/src/components/UI/ContainerTwo';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';

import SectionWrapper, { SectionHeader, ServiceWrapper } from './service.style';
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,  
  BuiltWith
} from './portfolioShowcase.style';
// import data from 'common/src/data/AgencyModern';

const Services = ({ servicesOrProducts, titleText, subtitleText,
  secHeading,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
  cardStyle
}) => {  
  return (
    <SectionWrapper id="services">
      <Container>
        <Fade up delay={100}>
          <SectionHeader>
            <Heading 
              {...secHeading}
              content={titleText} />
            <Text content={subtitleText} />
          </SectionHeader>
        </Fade>
        <ServiceWrapper>
          
          {servicesOrProducts && servicesOrProducts.map((item, index) => {
            return (
              ((item && item.featuredImage) &&      
              
              <Card key={`service-card-${index}`} className="service__item" {...cardStyle}>                    
                <Image
                        fluid={
                          (item.featuredImage !== null) | undefined
                            ? item.featuredImage.fluid
                            : {}
                        }
                        src={item.featuredImage.fluid.src}
                        alt={`Imagen ${item.id}`}
                        objectFit="cover"
                        className="service__image"
                      />
                <Box className="service__content">
                  <Heading as="h4" content={item.name} />
                  <Text className="service__description" as="div"
                                dangerouslySetInnerHTML={{
                                  __html: item.description.childMarkdownRemark.html,
                                }}
                                {...detailsStyle}
                              />                  
                  <Text className="service__priceDescription" content={item.priceDescription} />
                </Box>
              </Card>
              /*
                <FeatureBlock
                  key={`post_key-${index}`}
                  id={`post_id-${item.id}`}
                  className="service__item"
                  icon={
                    <Image
                      fluid={
                        item.featuredImage
                          ? item.featuredImage.fluid
                          : {}
                      }
                      src={item.featuredImage.fluid.src}
                      alt={`Imagen ${item.id}`}
                      objectFit="cover"
                      className="service__image"
                    />
                  }                  
                  iconPosition="left"
                  title={<Heading as="h4" content={item.name} />}
                  description={<Text content={item.description.childMarkdownRemark.html.replace(/(<([^>]+)>)/gi, "")} />}
                /> */
              )
            );
          })}
        </ServiceWrapper>        
      </Container>
    </SectionWrapper>
  );
};

Services.propTypes = {
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

Services.defaultProps = {  
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  // Card default style
  cardStyle: {
    p: ['20px 20px', '30px 20px', '30px 20px', '53px 40px'],
    borderRadius: '10px',
  }
};

export default Services;
