import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Container from 'common/src/components/UI/ContainerTwo';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import { Link, navigate } from 'gatsby';

import SectionWrapper, { SectionHeader, ServiceWrapper } from './service.style';

const ProductsCatalogIntro = ({ servicesOrProducts, titleText, subtitleText,
  secHeading,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {  
  return (
    <SectionWrapper id="products-catalog-intro">
      <Container>
        <Fade up delay={100}>
          <SectionHeader>
            <Heading 
              {...secHeading}
              content={titleText} />
            <Text content={subtitleText} />
          </SectionHeader>
        </Fade>        
        <Box flexBox={true} justifyContent="center" marginTop="15px" marginBottom="60px">                
            <Button title="Ver Nuestros Productos" onClick={() => { navigate("/products");}} />
        </Box> 
        </Container>  
    </SectionWrapper>
  );
};

ProductsCatalogIntro.propTypes = {
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

ProductsCatalogIntro.defaultProps = {  
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default ProductsCatalogIntro;
